/** @jsx jsx */

import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { jsx } from '@emotion/react';
import LayoutStandAlone from '../../../components/layoutStandAlone';
import Seo from '../../../components/seo';
import IndexFreeTrial from '../../../components/indexFreeTrial';

import ReactPlayer from 'react-player';

import bcTestimonialsThumbnail from '../../../images/bigCommerceTestimonials.jpg';
import bcRecommendationThumbnail from '../../../images/bigCommerceRecommendation.jpg';
import bcHowZycadaWorksThumbnail from '../../../images/bigCommerceHowZycadaWorks.jpg';

import transparentThumbnail from '../../../images/transparent.png';
import markHedman from '../../../images/markHedman150.jpg';
import lapgLogo from '../../../images/lapolicegearLogo.png';

import one from '../../../images/one.png';
import two from '../../../images/two.png';

import FtrClock from 'react-feather/dist/icons/clock';
import FtrEdit from 'react-feather/dist/icons/edit';
import FtrImage from 'react-feather/dist/icons/image';
import FtrSettings from 'react-feather/dist/icons/settings';
import FtrActivity from 'react-feather/dist/icons/activity';
import FtrSearch from 'react-feather/dist/icons/search';
import FtrTrendUp from 'react-feather/dist/icons/trending-up';
import FtrDollar from 'react-feather/dist/icons/dollar-sign';
import FtrArrowRight from 'react-feather/dist/icons/arrow-right';

const BigCommerceBanner = () => (
  <div className="pt-24">
    <div className="">
      <div className="container flex flex-col md:flex-row justify-center items-start py-6">
        <div className="md:w-3/4 text-center">
          <h1 className=" display-4 fs-5 text-zycadaDarkPurple ">
            BigCommerce and Zycada partner to deliver the fastest online shopping experience
          </h1>
          <h3 className="text-black">
            <span className=" ">10x faster in under 10 days. No code changes.</span>
          </h3>
          <Link to={'#get-in-touch'}>
            <button
              className={
                'mb-4 mx-2 sm:ml-0 sm:mb-0 min-w-60 max-w-60 relative text-white p-2 h-10 bg-btn-klean border  border-solid border-transparent rounded-md focus:outline-none hover:-translate-y-1 font-bold transition ease-in-out delay-150 duration-300 '
              }
            >
              Start a free trial
            </button>
          </Link>
        </div>
      </div>
    </div>
    <div className="bg-klean-gradient-bg pt-6 py-12">
      <div className="container flex flex-col sm:flex-row justify-center items-center">
        <div className="sm:w-1/2 self-center text-center sm:text-left px-4">
          <div className="flex flex-col items-center md:items-start my-auto">
            <div className="py-2">
              <StaticImage
                src="../../../images/BplusZ.png"
                quality={95}
                width={150}
                objectFit={'contain'}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="BplusZ"
              />
            </div>
            <div className={'text-center md:text-left'}>
              <h2 className="text-white"> Watch why BigCommerce merchants like you are using Zycada </h2>
            </div>
          </div>

          <h3 className="mt-3 mb-3 text-black">Accelerate your site. Now!</h3>
          <Link to={'https://calendly.com/kerry-zycada/zycada-in-23-minutes'}>
            <button
              className={
                'mb-4 mx-2 sm:ml-0 sm:mb-0 min-w-60 max-w-60 relative text-white p-2 h-10  border border-solid border-white rounded-md focus:outline-none hover:-translate-y-1 font-bold transition ease-in-out delay-150 duration-300 '
              }
            >
              Book a meeting
            </button>
          </Link>
        </div>
        <div className="sm:w-1/2">
          <div className="text-center align-items-center">
            <img src={transparentThumbnail} alt="BigCommerce + Zycada Testimonials" width="100%" />
            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                url="https://fast.wistia.com/medias/00vbju3z9e"
                controls
                width="100%"
                height="100%"
                playing
                light={bcTestimonialsThumbnail}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const BigCommerceTestimonials = () => (
  <div className="pt-6">
    <div className="">
      <div className="container flex flex-col sm:flex-row justify-center items-center w-4/5">
        <div className="flex flex-col sm:flex-row xl:flex-wrap justify-center my-auto">
          <div className={'p-2 mx-auto sm:mx-4'}>
            <img src={markHedman} className={'rounded-3x border-klean-gradient'} alt={'Mark Hedman'} />
          </div>
          <div className={'sm:w-3/5 self-center sm:pr-4 md:pr-4 lg:pr-0'}>
            <img src={lapgLogo} className={'mb-4'} alt={'LA Police Gear'} />
            <h4 className={'mb-4 text-sm'}>
              "Zycada has been fantastic for us! We are on BigCommerce and have always struggled a bit with speed. It
              was an incredibly painless process and our site has never been faster. Highly Recommend!"
            </h4>
            <h3 className={'mb-1 text-zycadaDarkPurple'}>Mark Hedman</h3>
            <h4 className={'mb-1 text-zycadaMediumPurple1'}>CEO, LA Police Gear</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const BigCommerceMoreSites = () => (
  <div className="pt-6">
    <div className="">
      <div className="container flex flex-wrap justify-center items-start sm:w-4/5 py-6">
        <div className={'text-center mx-auto'}>
          <h1 className={'text-zycadaDarkPurple px-4'}>More BigCommerce sites accelerated by Zycada</h1>
        </div>

        <div className={'py-4 flex flex-col flex-wrap justify-center'}>
          <div className={'grid sm:grid-cols-2 lg:grid-cols-4 gap-2'}>
            <div
              className={
                'flex flex-col items-center mx-12 sm:mx-2 my-4 py-6 px-4 bg-white/20 rounded-5xl border border-white text-center sm:text-left'
              }
            >
              <a href={'https://www.bbwheelsonline.com'} target="_blank" rel="noreferrer">
                <StaticImage
                  src="../../images/bbwheelsLogo.png"
                  quality={95}
                  width={150}
                  objectFit={'contain'}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="BBWheels"
                />
              </a>
              <p className="lg:min-h-25 lg:max-h-25 pt-6">
                "Since we turned on Zycada, our conversion rate is up. In the entire wheel industry, we are seeing
                decrease in sales. But we are seeing increase in sales and better conversion rate than pre-turning on
                [Zycada] platform."
              </p>
            </div>
            <div
              className={
                'flex flex-col items-center mx-12 sm:mx-2 my-4 py-6 px-4 bg-white/20 rounded-5xl border border-white text-center sm:text-left'
              }
            >
              <a href={'https://www.echogear.com'} target="_blank" rel="noreferrer">
                <StaticImage
                  src="../../images/echogearLogo.png"
                  quality={95}
                  width={150}
                  objectFit={'contain'}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="EchoGear"
                />
              </a>
              <p className="lg:min-h-25 lg:max-h-25 pt-6">
                "On top of the user experience metrics, which are great leading indicators, we've seen strong
                improvements in conversion rate, revenue, and average order value"
              </p>
            </div>
            <div
              className={
                'flex flex-col items-center mx-12 sm:mx-2 my-4 py-6 px-4 bg-white/20 rounded-5xl border border-white text-center sm:text-left'
              }
            >
              <a href={'https://www.enjukuracing.com'} target="_blank" rel="noreferrer">
                <StaticImage
                  src="../../images/enjukuracingLogo.png"
                  quality={95}
                  width={150}
                  objectFit={'contain'}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="EnjukuRacing"
                />
              </a>
              <p className="lg:min-h-25 lg:max-h-25 pt-6">
                "Zycada offers powerful results for our BigCommerce site. We have improved site speed over our
                competitors in many ways thanks to Zycada’s Team."
              </p>
            </div>
            <div
              className={
                'flex flex-col items-center mx-12 sm:mx-2 my-4 py-6 px-4 bg-white/20 rounded-5xl border border-white text-center sm:text-left'
              }
            >
              <a href={'https://www.murad.com'} target="_blank" rel="noreferrer">
                <StaticImage
                  src="../../images/muradLogo.png"
                  quality={95}
                  width={150}
                  objectFit={'contain'}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Murad"
                />
              </a>
              <p className="lg:min-h-25 lg:max-h-25 pt-6">
                "Wow! I'm impressed. The speed is unbelievable. The site is faster than ever without us doing any code
                change."
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const BigCommerceRecommendation = () => (
  <div className={'bg-klean-gradient-bg pt-6'}>
    <div className="">
      <div className="container flex flex-col sm:flex-row justify-center items-center">
        <div className="sm:w-2/5 px-6">
          <div className={'player-wrapper'}>
            <img src={transparentThumbnail} width="100%" alt="WHWC Recommendation" />
            <ReactPlayer
              className="react-player"
              url="https://fast.wistia.com/medias/prhj7zoxx8"
              controls
              width="100%"
              height="100%"
              playing
              light={bcRecommendationThumbnail}
            />
          </div>
        </div>
        <div className="sm:w-3/5  px-6">
          <div className="pb-6">
            <h3 className={'mb-3 text-white'}>
              "Recommending Zycada to other BigCommerce merchants is about as close to a no-brainer as it gets"
            </h3>
            <h3 className={'mb-1 text-zycadaDarkPurple'}>Jon Morgan</h3>
            <p className={'mb-1 text-black'}>Digital Product Manager, E-Commerce, WHWC</p>
            <div className="flex flex-col items-center sm:items-start pt-6">
              <Link to={'#get-in-touch'}>
                <button
                  className={
                    'mb-4 mx-2 sm:ml-0 sm:mb-0 min-w-60 max-w-60 relative text-white p-2 border border-solid rounded-md focus:outline-none hover:-translate-y-1 font-bold transition ease-in-out delay-150 duration-300'
                  }
                >
                  Try Zycada for free
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const BigCommerceHowToActivateZycada = () => (
  <div className={'pt-6'} id="installation">
    <div className="container flex flex-col sm:flex-row justify-center items-center">
      <div className="p-6">
        <div className={'text-center pt-6'}>
          <h1 className={'text-zycadaDarkPurple'}>Setup Zycada in 2 simple steps</h1>
          <h2 className={'text-black'}>within 30 minutes</h2>
        </div>
        <div className="flex flex-none flex-col md:flex-row items-center md:items-start my-auto">
          <div className={'p-2'}>
            <img src={one} className={'rounded-3x border-klean-gradient'} alt={'one'} />
          </div>
          <div className={'self-center text-center md:text-left md:px-4'}>
            <h3 className={''}>Configure service in Zycada platform with TLS certificate</h3>
          </div>
        </div>
        <div className="flex flex-none flex-col md:flex-row items-center md:items-start my-auto">
          <div className={'p-2'}>
            <img src={two} className={'rounded-3x border-klean-gradient'} alt={'two'} />
          </div>
          <div className={'self-center text-center md:text-left md:px-4'}>
            <h3 className={''}>Set site's DNS entry to Zycada CNAME</h3>
          </div>
        </div>
      </div>
    </div>
    <div className="pb-12 justify-center items-center text-center">
      <h2 className={'text-zycadaDarkPurple px-6 sm:py-6 mb-4 mx-2 sm:ml-0 sm:mb-0 text-center '}>
        Be faster than your competition. Activate Zycada Today!
      </h2>
      <Link to={'https://calendly.com/kerry-zycada/zycada-in-23-minutes'}>
        <button
          className={
            'mb-4 mx-2 sm:ml-0 sm:mb-0 min-w-60 max-w-60 relative text-white p-2  bg-btn-klean border border-transparent rounded-md focus:outline-none hover:-translate-y-1 font-bold transition ease-in-out delay-150 duration-300'
          }
        >
          Schedule a time
        </button>
      </Link>
    </div>
  </div>
);

const BigCommerceQuestions = () => (
  <div className={'pt-6 bg-zycadaMediumPurple2/20'}>
    <div className="">
      <div className={'flex flex-col md:flex-row flex-wrap w-full md:w-4/5 justify-center items-center mx-auto py-8'}>
        <div className={'w-full text-center pt-6'}>
          <h1 className={'text-zycadaDarkPurple'}>Questions?</h1>
        </div>
        <div className={'w-full md:w-1/2 lg:w-1/4 py-2 px-4'}>
          <div className={'flex flex-col items-center w-full'}>
            <div className={'flex justify-center'}>
              <div className={'zycadaIconWrapper zycadaShadowSecondary flex pb-8 text-center'}>
                <div className={'zycadaIcon text-violet-400 min-h-10 max-h-10'}>
                  <FtrClock />
                </div>
              </div>
            </div>
            <h3 className={'text-base text-center'}>Setup Time?</h3>
            <p className={'text-center min-h-12 max-h-12'}>
              Setup takes less than 30min. Configure your service with TLS cert and change your DNS.
            </p>
          </div>
        </div>
        <div className={'w-full md:w-1/2 lg:w-1/4 py-2 px-4'}>
          <div className={'flex flex-col items-center w-full'}>
            <div className={'flex justify-center'}>
              <div className={'zycadaIconWrapper zycadaShadowSecondary flex pb-8 text-center'}>
                <div className={'zycadaIcon text-violet-400 min-h-10 max-h-10'}>
                  <FtrEdit />
                </div>
              </div>
            </div>
            <h3 className={'text-base text-center'}>App Updates?</h3>
            <p className={'text-center min-h-12 max-h-12'}>
              Zycada bots are app-aware and adapt using Real User Behavioral Intelligence (RUBI)
            </p>
          </div>
        </div>
        <div className={'w-full md:w-1/2 lg:w-1/4 py-2 px-4'}>
          <div className={'flex flex-col items-center w-full'}>
            <div className={'flex justify-center'}>
              <div className={'zycadaIconWrapper zycadaShadowSecondary flex pb-8 text-center'}>
                <div className={'zycadaIcon text-violet-400'}>
                  <FtrImage />
                </div>
              </div>
            </div>
            <h3 className={'text-base text-center'}>Image Optimization?</h3>
            <p className={'text-center min-h-12 max-h-12'}>
              Images are optimized for faster delivery based on client's device and browser
            </p>
          </div>
        </div>
        <div className={'w-full md:w-1/2 lg:w-1/4 py-2 px-4'}>
          <div className={'flex flex-col items-center w-full'}>
            <div className={'flex justify-center'}>
              <div className={'flex items-center zycadaIconWrapper zycadaShadowSecondary pb-8 text-center'}>
                <div className={'zycadaIcon text-violet-400 min-h-10 max-h-10'}>
                  <FtrSettings />
                </div>
              </div>
            </div>
            <h3 className={'text-base text-center'}>Headless Application?</h3>
            <p className={'text-center min-h-12 max-h-12'}>
              Zycada accelerates API calls to support micro-services architecture for headless applications
            </p>
          </div>
        </div>
        <div className={'w-full md:w-1/2 lg:w-1/4 py-2 px-4'}>
          <div className={'flex flex-col items-center w-full'}>
            <div className={'flex justify-center'}>
              <div className={'flex items-center zycadaIconWrapper zycadaShadowSecondary pb-8 text-center'}>
                <div className={'zycadaIcon text-violet-400 min-h-10 max-h-10'}>
                  <FtrActivity />
                </div>
              </div>
            </div>
            <h3 className={'text-base text-center'}>Core Web Vitals?</h3>
            <p className={'text-center min-h-10 max-h-10'}>
              Zycada accelerates dynamic pages by 10x that has a direct positive impact on Core Web Vitals
            </p>
          </div>
        </div>
        <div className={'w-full md:w-1/2 lg:w-1/4 py-2 px-4'}>
          <div className={'flex flex-col items-center w-full'}>
            <div className={'flex justify-center'}>
              <div className={'flex items-center zycadaIconWrapper zycadaShadowSecondary pb-8 text-center'}>
                <div className={'zycadaIcon text-violet-400 min-h-10 max-h-10'}>
                  <FtrSearch />
                </div>
              </div>
            </div>
            <h3 className={'text-base text-center'}>SEO?</h3>
            <p className={'text-center min-h-10 max-h-10'}>
              Page Speed is a significant factor for search engine's page ranking. Faster is better.
            </p>
          </div>
        </div>
        <div className={'w-full md:w-1/2 lg:w-1/4 py-2 px-4'}>
          <div className={'flex flex-col items-center w-full'}>
            <div className={'flex justify-center'}>
              <div className={'flex items-center zycadaIconWrapper zycadaShadowSecondary pb-8 text-center'}>
                <div className={'zycadaIcon text-violet-400 min-h-10 max-h-10'}>
                  <FtrTrendUp />
                </div>
              </div>
            </div>
            <h3 className={'text-base text-center'}>Business Metrics?</h3>
            <p className={'text-center min-h-10 max-h-10'}>
              Faster site boosts revenue and conversion. Montior business metrics with 3rd party analytics.
            </p>
          </div>
        </div>
        <div className={'w-full md:w-1/2 lg:w-1/4 py-2 px-4'}>
          <div className={'flex flex-col items-center w-full'}>
            <div className={'flex justify-center'}>
              <div className={'flex items-center zycadaIconWrapper zycadaShadowSecondary pb-8 text-center'}>
                <div className={'zycadaIcon text-violet-400 min-h-10 max-h-10'}>
                  <FtrDollar />
                </div>
              </div>
            </div>
            <h3 className={'text-base text-center'}>Pricing?</h3>
            <p className={'text-center min-h-10 max-h-10'}>
              Price starts at $250 per month and is based on the number of Page Views or Requests per month.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="p-3 flex flex-col sm:flex-row justify-center items-center">
      <h2 className={'text-zycadaDarkPurple px-6 sm:py-6 mb-4 mx-2 sm:ml-0 sm:mb-0 text-center sm:text-left '}>
        More Questions?
      </h2>
      <Link to={'https://calendly.com/kerry-zycada/zycada-in-23-minutes'}>
        <button
          className={
            'mb-4 mx-2 sm:ml-0 sm:mb-0 min-w-60 max-w-60 relative text-white p-2  bg-btn-klean border border-transparent rounded-md focus:outline-none hover:-translate-y-1 font-bold transition ease-in-out delay-150 duration-300'
          }
        >
          Let's talk
        </button>
      </Link>
    </div>
  </div>
);
const BigCommerceHowZycadaWorks = () => (
  <div className={'pt-6'} id="howitworks">
    <div className="">
      <div className="container flex flex-col sm:flex-row justify-center items-center">
        <div className="p-6">
          <div className={'w-full text-center pt-6'}>
            <h1 className={'text-zycadaDarkPurple'}>How Zycada Works?</h1>
          </div>

          <div className={'player-wrapper'}>
            <img src={transparentThumbnail} width="100%" alt="How Zycada Works?" />
            <ReactPlayer
              className="react-player"
              url="https://fast.wistia.com/medias/v1lyyy5rau"
              controls
              width="100%"
              height="100%"
              playing
              light={bcHowZycadaWorksThumbnail}
            />
          </div>
        </div>
      </div>
    </div>
    <div className="flex flex-col sm:flex-row justify-center items-center text-center sm:text-left bg-white/20 border border-white">
      <h2 className={'text-zycadaDarkPurple px-6 sm:py-6 mb-4 mx-2 sm:ml-0 sm:mb-0 text-center '}>
        Let us show you a demo
      </h2>
      <Link to={'https://calendly.com/kerry-zycada/zycada-in-23-minutes'}>
        <button
          className={
            'mb-4 mx-2 sm:ml-0 sm:mb-0 min-w-60 max-w-60 relative text-white p-2  bg-btn-klean border border-transparent rounded-md focus:outline-none hover:-translate-y-1 font-bold transition ease-in-out delay-150 duration-300'
          }
        >
          Reserve a slot
        </button>
      </Link>
    </div>
  </div>
);

const BigCommerceIndustryResearch = () => (
  <div className={'py-6'}>
    <div className="">
      <div className={'flex flex-col flex-wrap align-middle justify-center w-full sm:w-4/5 sm:mx-auto'}>
        <div className={'w-full text-center pt-8'}>
          <h1 className={'text-zycadaDarkPurple'}>How speed impacts eCommerce?</h1>
        </div>

        <div className={'py-4 flex flex-col flex-wrap justify-start'}>
          <div className={'grid sm:grid-cols-1 lg:grid-cols-3 gap-2'}>
            <div className={'flex flex-col mx-12 sm:mx-4 my-4 p-6 text-center items-center sm:text-left'}>
              <StaticImage
                src="../../images/deloitteLogo.png"
                quality={95}
                objectFit={'contain'}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Deloitte"
                className={'mx-16 sm:mx-8'}
              />
              <h3 className="pt-4 text-center text-zycadaDarkPurple">
                Milliseconds <br />
                Make Millions
              </h3>
              <h4 className="text-center">
                {' '}
                A study on how improvements in mobile site speed positively affect a brand's bottom line
              </h4>

              <Link
                target="_blank"
                to={
                  'https://www2.deloitte.com/content/dam/Deloitte/ie/Documents/Consulting/Milliseconds_Make_Millions_report.pdf'
                }
              >
                <button
                  className={'mb-4 mx-2 sm:ml-0 sm:mb-0 min-w-60 max-w-60 relative text-zycadaDarkPurple font-bold'}
                >
                  <div className="flex flex-wrap justify-center item-center">
                    <div>View Study</div>
                    <div>
                      <FtrArrowRight />
                    </div>
                  </div>
                </button>
              </Link>
            </div>
            <div className={'flex flex-col mx-12 sm:mx-4 my-4 p-6 text-center items-center sm:text-left'}>
              <StaticImage
                src="../../images/portentLogo.png"
                quality={95}
                objectFit={'contain'}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Portent"
                className={'mx-16 sm:mx-8'}
              />
              <h3 className="pt-4 text-center text-zycadaDarkPurple">Site Speed Affects Conversion Rate</h3>
              <h4 className="text-center">
                {' '}
                An analysis based on conversion data from 20 websites with over 100 million page views
              </h4>
              <Link
                target="_blank"
                to={'https://www.portent.com/blog/analytics/research-site-speed-hurting-everyones-revenue.htm'}
              >
                <button
                  className={'mb-4 mx-2 sm:ml-0 sm:mb-0 min-w-60 max-w-60 relative text-zycadaDarkPurple font-bold'}
                >
                  <div className="flex flex-wrap justify-center item-center">
                    <div>View Study</div>
                    <div>
                      <FtrArrowRight />
                    </div>
                  </div>
                </button>
              </Link>
            </div>
            <div className={'flex flex-col mx-12 sm:mx-4 my-4 p-6 text-center items-center sm:text-left'}>
              <StaticImage
                src="../../images/googleLogo.png"
                quality={95}
                objectFit={'contain'}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Google"
                className={'mx-16 sm:mx-8'}
              />
              <h3 className="pt-4 text-center text-zycadaDarkPurple">Page Load Time Impacts Bounce Rate</h3>
              <h4 className="text-center">
                An industry benchmark for page speed and how page load time impacts bounce rate
              </h4>
              <Link
                target="_blank"
                to={
                  'https://www.thinkwithgoogle.com/marketing-strategies/app-and-mobile/mobile-page-speed-new-industry-benchmarks/'
                }
              >
                <button
                  className={'mb-4 mx-2 sm:ml-0 sm:mb-0 min-w-60 max-w-60 relative text-zycadaDarkPurple font-bold'}
                >
                  <div className="flex flex-wrap justify-center item-center">
                    <div>View Study</div>
                    <div>
                      <FtrArrowRight />
                    </div>
                  </div>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const eCommerceBigCommerce = () => (
  <LayoutStandAlone>
    <Seo title="BigCommerce and Zycada partner to deliver the fastest online shopping experience" />

    <BigCommerceBanner />

    <BigCommerceTestimonials />

    <BigCommerceMoreSites />

    <BigCommerceRecommendation />

    <BigCommerceHowToActivateZycada />

    <BigCommerceQuestions />

    <BigCommerceHowZycadaWorks />

    <BigCommerceIndustryResearch />

    <div id="support" />
    <IndexFreeTrial />
  </LayoutStandAlone>
);

export default eCommerceBigCommerce;
